<template>
  <div class="page-content">
    <md-app md-waterfall md-mode="fixed">
      <md-app-toolbar class="md-primary">
        <span class="md-title">Phân quyền</span>
        <md-button v-shortkey="['ctrl', 'l']" @shortkey="submit()" @click="submit()" class="md-raised md-accent"><span>L</span>ưu<md-tooltip>Lưu (Ctrl + L)</md-tooltip></md-button>
      </md-app-toolbar>

      <md-app-drawer md-permanent="full">
        <md-toolbar class="md-transparent" md-elevation="0">
            Danh sách nhóm quyền
        </md-toolbar>
        <md-list>
            <md-list-item @click="changeRole(item.id)" v-for="item in roles" :key="'role_' + item.id">
                <md-icon>arrow_right</md-icon>
                <span class="md-list-item-text" :class="{ 'active': item.id == roleId }">{{item.roleName}}</span>
            </md-list-item>
        </md-list>
      </md-app-drawer>

      <md-app-content style="padding: 0;">
        <div class="grid">
            <div class="page-list-body">
                <div class="table-content">
					<ul>
                        <li>
                            <div class="item">
                                <div style="width: 40%;padding-left:10px; font-weight:500;">Tên module</div>
                                <div v-for="item in data.actions" :key="'action_' + item.id" style="width: 15%;">{{item.actionCode}}</div>
                            </div>
                        </li>
                        <li v-for="item in data.permissions" :key="item.id">
                            <div class="item">
                                <div style="width: 40%;padding-left:10px; font-weight:500;">{{item.moduleName}}</div>
                            </div>
                            <ul>
                                <li v-for="sub in item.children" :key="sub.id">
                                    <div class="item">
                                        <div style="width: 40%; padding-left:80px;">|_ {{sub.moduleName}}</div>
                                        <div v-for="action in sub.roleActions" :key="'m_' + sub.id + '_' + action.actionCode" style="width: 15%;">
                                            <label class="checkbox">
                                                <input v-model="action.hasRole" :checked="action.hasRole" type="checkbox" /> 
                                                <span class="helping-el"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <ul>
                                        <li v-for="s in sub.children" :key="s.id">
                                            <div class="item">
                                                <div style="width: 40%; padding-left:150px;">|_ {{s.moduleName}}</div>
                                                <div v-for="a in s.roleActions" :key="'m_' + s.id + '_' + a.actionCode" style="width: 15%;">
                                                    <label class="checkbox">
                                                        <input v-model="a.hasRole" :checked="a.hasRole" type="checkbox" /> 
                                                        <span class="helping-el"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
				</div>
            </div>
        </div>
      </md-app-content>
    </md-app>
  </div>
</template>

<script>
import roleService from '../../../api/roleService';
import messageBox from '../../../utils/messageBox';
import permissionService from '../../../api/permissionService';
import { mapActions } from 'vuex';

export default {
    metaInfo: {
        title: 'Phân quyền'
    },
    data() {
        return {
            loadding: false,
            data: [],
            submitted: false,
            roles: [],
            roleId: 0,
        }
    },
    created(){
        this.getData();
        this.getRoles();
    },
    
    methods: {
        ...mapActions('common', ['setLoading']),

        submit(){
            this.submitted = true;
            if(this.roleId > 0){
                this.save();
            }
            else{
                messageBox.showWarning("Vui lòng chọn nhóm quyền");
            }
        },

        save(){
            this.setLoading(true);
            permissionService.save(this.data.permissions).then((response) => {
                this.submitted = false;
                if(response.statusCode == 200){
                    messageBox.showMessage("Cập nhật thành công");
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => { this.setLoading(false);});
        },

        refresh(){
            this.roleId = 0;
            this.moduleName = '';
            this.getData();
            this.getRoles();
        },

        changeRole(id){
            this.roleId = id;
            this.getData();
        },

        getData(){
            this.loadding = true;
            permissionService.getByRoleId(this.roleId).then((response) => {
                if(response.statusCode == 200){
                    this.data = response.data;
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => { this.loadding = false; });
        },

        getRoles(){
            roleService.getAll()
            .then((response) => {
                if(response.statusCode == 200){
                    this.roles = response.data;
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally();
        },
    },
    watch: {
        roleId: function () {
            this.getData();
        },
    }
}
</script>


<style lang="css" scoped>
    .md-app {
        min-height: calc(100vh - 110px);
        border: 1px solid rgba(#000, .12);
    }
    .md-drawer {
        width: 300px;
        max-width: calc(100vw - 155px);
    }
    .md-list-item-text {
        color: black;
    }

    .md-list-item-text.active{
        color: var(--color-primary);
    }

    .item {
        display: flex;
        height: 35px;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #eee;
    }

    .item .action i {
        font-size: 14px!important;
        color: #fff!important;
    }
</style>
